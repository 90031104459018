import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase'; // Ensure the path is correct
import Loading from './svg/loading.svg';
import './pages/css/loading.css';
import './App.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Lazy load the pages and components
const Home = React.lazy(() => import('./pages/Home'));
const Login = React.lazy(() => import('./pages/Login'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const AdminLogin = React.lazy(() => import('./pages/AdminLogin'));
const AdminSignUp = React.lazy(() => import('./pages/AdminSignUp'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));
const Games = React.lazy(() => import('./pages/dashboard/Games'));
const Rules = React.lazy(() => import('./pages/dashboard/Rules'))
const Quizzes = React.lazy(() => import('./pages/dashboard/Quizzes'));
const UserProfile = React.lazy(() => import('./pages/dashboard/UserProfile'));
const Notifications = React.lazy(() => import('./pages/dashboard/Notifications'));
const Payment = React.lazy(() => import('./pages/dashboard/Payment'));
const PrivacyPolicy = React.lazy(() => import('./pages/dashboard/PrivacyPolicy'));
const AdminPanel = React.lazy(() => import('./pages/AdminPanel'));
const ManageQuizzes = React.lazy(() => import('./pages/ManageQuizzes'));
const ManageUsers = React.lazy(() => import('./pages/ManageUsers'));
const ProtectedRoute = React.lazy(() => import('./components/ProtectedRoute'));
const AdminRoute = React.lazy(() => import('./components/AdminRoute'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const ManagePayments = React.lazy(() => import('./pages/AdminPayments'));
const Messages = React.lazy(() => import('./pages/Messages'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const WithdrawalHistory = React.lazy(() => import('./pages/WithdrawalHistory'));


function App() {
  const [user, loading, error] = useAuthState(auth); // Track user authentication state

  if (loading) {
    return (
      <div className="loading-container">
        <img src={Loading} alt="Loading..." />
      </div>
    ); // Display a loading message while checking auth state
  }

  if (error) {
    return <div className="error-message">Error: {error.message}</div>; // Handle authentication error
  }

  return (
    <Router>
      <Suspense
        fallback={
          <div className="loading-container">
            <img src={Loading} alt="Loading..." />
          </div>
        }
      >
        <TransitionGroup>
          <CSSTransition timeout={300} classNames="fade">
            <Routes>
              <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Home />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/signup" element={<AdminSignUp />} />
              <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
              <Route path="/signup" element={user ? <Navigate to="/dashboard" /> : <SignUp />} />
              <Route path="/admin/forgot-password" element={<ForgotPassword />} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/admin" element={<AdminRoute><AdminPanel /></AdminRoute>} />
              <Route path="/admin/quizzes" element={<AdminRoute><ManageQuizzes /></AdminRoute>} />
              <Route path="/admin/users" element={<AdminRoute><ManageUsers /></AdminRoute>} />
              <Route path="/admin/payments" element={<AdminRoute><ManagePayments /></AdminRoute>} />
              <Route path="/admin/messages" element={<AdminRoute><Messages /></AdminRoute>} />
              <Route path="/admin/withdrawal-history" element={<AdminRoute><WithdrawalHistory /></AdminRoute>} />
          
              {/* Nested dashboard routes (additional features) */}
              <Route path="/dashboard/games/" element={<ProtectedRoute><Games /></ProtectedRoute>} />
              <Route path="/dashboard/rules/" element={<ProtectedRoute><Rules /></ProtectedRoute>} />
             
              <Route path="/dashboard/quizzes" element={<ProtectedRoute><Quizzes /></ProtectedRoute>} />
              <Route path="/dashboard/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
              <Route path="/dashboard/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
              <Route path="/dashboard/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
              <Route path="/dashboard/privacy-policy" element={<ProtectedRoute><PrivacyPolicy /></ProtectedRoute>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </Suspense>
    </Router>
  );
}

export default App;
