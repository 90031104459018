// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signInWithRedirect
} from "firebase/auth";
import { getDatabase, ref, set, get, update, remove } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZLHskkUC19zJsRwUdWOucDbRwHssLp8w",
  authDomain: "amresh-teen-app.firebaseapp.com",
  projectId: "amresh-teen-app",
  storageBucket: "amresh-teen-app.appspot.com",
  messagingSenderId: "363925479230",
  appId: "1:363925479230:web:b8d1662989327fb652166f",
  measurementId: "G-6NYLC12TR4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);

// Set authentication persistence to local
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Auth persistence set to local.");
  })
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

// Google login function
export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user; // Return user information
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw error; // Rethrow the error for further handling
  }
};

// Generate a unique password for email signup
const generateUniquePassword = () => {
  return Math.random().toString(36).slice(-8); // Generates a random 8-character password
};

// Email signup with verification function
export const signUpWithEmail = async (email) => {
  const password = generateUniquePassword(); // Generate a unique password
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password); // Use the generated password
    await sendEmailVerification(userCredential.user); // Send verification email

    // Store admin data in the database
    await set(ref(db, `admins/${userCredential.user.uid}`), {
      email: userCredential.user.email,
      role: "admin",
    });

    return { user: userCredential.user, password }; // Return user information and generated password
  } catch (error) {
    console.error("Error during email signup:", error);
    throw error; // Rethrow the error for further handling
  }
};

// Password reset function
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email); // Send password reset email
  } catch (error) {
    console.error("Error sending password reset email:", error);
    throw error; // Rethrow the error for further handling
  }
};

// Sign in with email and password function
export const signInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await firebaseSignInWithEmailAndPassword(auth, email, password); // Sign in with email and password
    return userCredential.user; // Return user information
  } catch (error) {
    console.error("Error signing in with email and password:", error);
    throw error; // Rethrow the error for further handling
  }
};

// Check if the user is an admin
export const isAdmin = async (uid) => {
  const adminRef = ref(db, `admins/${uid}`);
  const snapshot = await get(adminRef);
  return snapshot.exists(); // Return true if admin exists, otherwise false
};

// Quiz utilities
export const getQuizzes = async () => {
  const quizzesRef = ref(db, 'quizzes/');
  const snapshot = await get(quizzesRef);
  return snapshot.exists() ? snapshot.val() : {}; // Return quizzes or empty object
};

export const addQuiz = async (quizData) => {
  const newQuizRef = ref(db, 'quizzes/' + quizData.id);
  await set(newQuizRef, quizData); // Add quiz data to the database
};

export const updateQuiz = async (id, updatedData) => {
  const quizRef = ref(db, 'quizzes/' + id);
  await update(quizRef, updatedData); // Update existing quiz data
};

export const deleteQuiz = async (id) => {
  const quizRef = ref(db, 'quizzes/' + id);
  await remove(quizRef); // Delete quiz data from the database
};

// User utilities
export const getUsers = async () => {
  const usersRef = ref(db, 'users/');
  const snapshot = await get(usersRef);
  return snapshot.exists() ? snapshot.val() : {}; // Return users or empty object
};

// Upload media files to Firebase Storage
export const uploadQuizMedia = async (file) => {
  const storageReference = storageRef(storage, 'quizzes/' + file.name);
  await uploadBytes(storageReference, file); // Upload the file
  const downloadURL = await getDownloadURL(storageReference); // Get the file's download URL
  return downloadURL; // Return the download URL
};


// Function to save user login data
export const saveUserLoginData = async (user) => {
  const userRef = ref(db, `users/${user.uid}`);
  const loginData = {
    email: user.email,
    lastLogin: new Date().toISOString(), // Store current date and time
    createdAt: user.metadata.creationTime, // Store the account creation time
  };

  try {
    await set(userRef, loginData); // Save login data to the database
    console.log("User login data saved successfully:", loginData);
  } catch (error) {
    console.error("Error saving user login data:", error);
  }
};
export { createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, GoogleAuthProvider, signInWithPopup,signInWithRedirect};